<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
     
      <CRow class="justify-content-center">
        <CCol md="7">
           <CCardGroup>
             <CCard
              text-color="white"
              class="text-center py-5 d-md-down-none logo-bg"
             >
            <CCardBody>
             <el-image
              style="width: 160px"
              src="img/CompanyLogo.png"
              fit="fit"
              >
              </el-image>
            </CCardBody>

              
            </CCard>
            <CCard
               class="p-3"
              body-wrapper
            >
           <CCardBody>
                <CForm>
                   
                  <h2>LucidiTV INVOICE</h2>

                  <p></p>
                  <p></p>
                  
                  <CRow>
                    <CCol col="6" class="text-center">
                      <CButton color="primary" class="px-2" @click="login"><h5>Login</h5></CButton>
                    </CCol>
                   </CRow>
                </CForm>
              </CCardBody>
              
            </CCard>
          </CCardGroup>

         
         
             
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
export default {
  name: 'Login',
  
  data () {
    return {
      isLoading: true
    }
  },
  created(){
    const self = this;
    firebase.auth().getRedirectResult().then(function(result) {

      if (result.credential) {
         self.$router.replace('/');
      }
      
      else{
        self.isLoading=false;
      }
    });
  },
  methods:{
    login(){
      var provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithRedirect(provider);
    }
  }
}
</script>
<style scoped>
.logo-bg {
  background-color:#000000B3;
}
</style>
